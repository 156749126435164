import React from "react"

const SectionTitle = ({ title, Tag = "h1", classes = [] }) => {
  const componentsClasses = [
    "font-title bg-pink leading-8 font-semibold section-title flex justify-center md:justify-left text-3xl md:text-4xl",
    ...classes,
  ].join(" ")

  return <Tag className={componentsClasses}>{title}</Tag>
}

export default SectionTitle
