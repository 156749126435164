import React from "react"
import { IoChevronBack, IoChevronForward } from "react-icons/io5"

const ArrowButtons = ({ previousSlide, nextSlide }) => {
  return (
    <>
      <button
        type="button"
        className="absolute z-10 flex items-center justify-center bg-white bg-opacity-70 p-0 border-none outline-none top-2/4 left-0 rounded-r-md"
        style={{
          height: "50px",
          width: "44px",
          transform: "translateY(-50%)",
        }}
        aria-label="Previous Image"
        onClick={previousSlide}
      >
        <IoChevronBack className="h-6 w-6" />
      </button>

      <button
        type="button"
        className="absolute z-10 flex items-center justify-center bg-white bg-opacity-70 p-0 border-none outline-none top-2/4 right-0 rounded-l-md"
        style={{
          height: "50px",
          width: "44px",
          transform: "translateY(-50%)",
        }}
        aria-label="Next Image"
        onClick={nextSlide}
      >
        <IoChevronForward className="h-6 w-6" />
      </button>
    </>
  )
}

export default ArrowButtons
