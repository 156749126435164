import React, { useState, useRef, useEffect } from "react"
import { useGesture } from "react-use-gesture"
import { animated, useSprings } from "react-spring"
import { GatsbyImage } from "gatsby-plugin-image"

import ProductThumbnails from "~/components/product-gallery-thumbs"
import ArrowButtons from "~/components/arrow-buttons"

const clamp = (input, lower, upper) => Math.min(Math.max(input, lower), upper)

const ProductGallery = ({
  images,
  thumbs,
  title,
  slidesAtOnce = 1,
  auto = 0,
  ...otherProps
}) => {
  const sliderRef = useRef(null)
  const [slide, setSlide] = useState(0)
  const [springProps, setSpringProps] = useSprings(images.length, index => ({
    offset: index,
  }))

  const onDrag = ({
    down,
    movement: [xDelta],
    direction: [xDir],
    distance,
    cancel,
    active,
  }) => {
    if (sliderRef && sliderRef.current && sliderRef.current.parentElement) {
      const { width } = sliderRef.current.parentElement.getBoundingClientRect()

      if (down && distance > width / 2) {
        if (cancel) cancel()
        if (active) {
          setSlide(
            clamp(slide + (xDir > 0 ? -1 : 1), 0, images.length - slidesAtOnce)
          )
        }
      }

      setSpringProps(index => ({
        offset: (active && down ? xDelta : 0) / width + (index - slide),
      }))
    }
  }

  const gestureBinds = useGesture(
    { onDrag },
    {
      drag: {
        delay: 200,
      },
    }
  )

  useEffect(() => {
    setSpringProps(index => ({ offset: index - slide }))
  }, [slide, setSpringProps])

  useEffect(() => {
    let interval

    if (auto > 0) {
      interval = setInterval(() => {
        const targetIndex = (slide + 1) % images.length
        setSlide(targetIndex)
      }, auto)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [auto, images.length, slide])

  const nextSlide = () => {
    if (slide === images.length - slidesAtOnce) return setSlide(0)
    return setSlide(slide + 1)
  }

  const previousSlide = () => {
    if (slide === 0) return setSlide(images.length - slidesAtOnce)
    return setSlide(slide - 1)
  }

  const slides = images.map((image, index) => (
    <GatsbyImage
      image={image.gatsbyImageData}
      key={image.id}
      loading={index === 0 ? "eager" : "lazy"}
      className="pointer-events-none select-none"
      alt={title}
    />
  ))

  const Slider = () => {
    return springProps.map(({ offset }, index) => (
      <animated.div
        {...gestureBinds()}
        key={index}
        className="absolute inset-0 flex justify-center items-center"
        style={{
          transform: offset.to(
            offsetX => `translate3d(${offsetX * 100}%, 0, 0)`
          ),
          width: `${100 / slidesAtOnce}%`,
          willChange: "transform",
        }}
      >
        {slides[index]}
      </animated.div>
    ))
  }

  return (
    <div ref={sliderRef} {...otherProps}>
      <div className="relative min-h-sm md:min-h-md overflow-hidden flex-1 flex items-center justify-center bg-gray-100 mb-2 md:mb-0 md:ml-2 lg:ml-0 lg:mb-4">
        <Slider />

        <ArrowButtons
          className="static z-10"
          previousSlide={previousSlide}
          nextSlide={nextSlide}
        />
      </div>

      <div className="">
        <ProductThumbnails
          images={thumbs}
          activeIndex={slide}
          className="overflow-scroll p-2 grid gap-2 grid-cols-4 w-full md:w-56 md:grid-cols-2 lg:w-full lg:grid-cols-4 xl:grid-cols-5"
          productTitle={title}
          onClick={setSlide}
        />
      </div>
    </div>
  )
}

export default ProductGallery
