import * as React from "react"
import { graphql, Link } from "gatsby"
import { IoHome } from "react-icons/io5"

import Seo from "~/components/seo"
import ProductGallery from "~/components/product-gallery"
import ProductForm from "~/components/product-form"
import SectionTitle from "~/components/section-title"
import ProductTabs from "~/components/product-tabs"
import Container from "~/components/container"

export default function Product({ data: { product, collection, thumbnails } }) {
  const { title, description, descriptionHtml, images } = product

  return (
    <>
      <Seo
        title={`${title} · ${collection.title}`}
        description={description}
        image={images.length ? images[0].originalSrc : null}
      />

      <section className="bg-orange h-auto py-3 shadow-lg">
        <div className="container mx-auto h-full px-2 sm:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 md:gap-6 h-full">
            <div className="flex flex-col sm:flex-row sm:justify-center items-center md:justify-start h-full md:space-x-3 text-gray-50 col-span-full xl:col-span-10 xl:col-start-2">
              <Link to="/">
                <IoHome className="w-4 h-4" />{" "}
              </Link>
              <span className="spacer font-thin hidden sm:inline">/</span>
              <Link to="/shop">Shop</Link>
              <span className="spacer font-thin hidden sm:inline">/</span>
              <Link to={`/shop/${collection.handle}`} className="capitalize">
                {collection.title}
              </Link>
              <span className="spacer font-thin hidden sm:inline">/</span>
              <span className="">{title}</span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 md:gap-y-14 xl:p-0 mb-8">
            <div className="xl:col-start-2 lg:col-span-6 xl:col-span-4">
              <SectionTitle
                title={title}
                classes={["md:inline-flex py-4 px-6 mb-8 lg:hidden lg:mb-10"]}
              />

              <ProductGallery
                className="w-full relative flex flex-col md:flex-row-reverse lg:flex-col"
                images={images}
                thumbs={thumbnails.images}
                title={title}
              />
            </div>

            <ProductForm
              className="lg:col-span-6 xl:col-span-6 mb-6 md:mb-0 xl:pl-8"
              product={product}
            />

            <div className="col-span-full xl:col-start-2 xl:col-span-10">
              <ProductTabs>
                <div label="Description">
                  <div
                    className="prose lg:prose-lg py-4 w-full"
                    dangerouslySetInnerHTML={{
                      __html: descriptionHtml,
                    }}
                  />
                </div>
                <div label="Delivery">
                  <div className="prose lg:prose-lg py-4 w-full">
                    <p>
                      Currently, I am only offering local delivery within
                      Winchester and the surrounding areas. I personally hand
                      deliver each arrangement, to make sure it arrives in the
                      best possible condition.
                    </p>

                    <p>
                      Please note, same day delivery is not possible after 11
                      am.
                    </p>

                    <p>
                      Please specify delivery date at check out. All deliveries
                      will be carried out in the afternoon between 5 and 6pm. If
                      this delivery time doesn’t suit you, please let me know by
                      calling{" "}
                      <a
                        href="tel:01962621097"
                        className="text-orange hover:text-orange-dark"
                        title="HeartBee Flowers Phone number"
                      >
                        01962 621 097
                      </a>
                      .
                    </p>

                    <p>
                      Delivery within a ten mile radius of Winchester is £5.00
                      and outside of a ten mile radius is £7.00
                    </p>
                  </div>
                </div>
              </ProductTabs>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!) {
    collection: shopifyCollection(
      ruleSet: { rules: { elemMatch: { condition: { eq: $productType } } } }
    ) {
      handle
      title
    }
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      productType
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        id
        gatsbyImageData(height: 560, layout: CONSTRAINED)
        originalSrc
      }
      variants {
        id
        title
        price
        availableForSale
        storefrontId
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    thumbnails: shopifyProduct(id: { eq: $id }) {
      images {
        id
        originalSrc
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
      }
    }
  }
`
