import React from "react"

const Button = ({ children, type = "button", classes = "", ...rest }) => {
  return (
    <button
      {...rest}
      className={
        (classes +=
          " bounce py-3 px-5 border border-transparent shadow rounded text-white text-md lg:text-lg font-medium bg-orange hover:bg-orange-dark disabled:opacity-50 disabled:hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange")
      }
      type={type}
    >
      {children}
    </button>
  )
}

export default Button
