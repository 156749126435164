import React, { useState } from "react"

const Tab = ({ label, activeTab, onClick }) => {
  let className =
    "px-4 py-2 bg-gray-100 border-gray border border-b-0 rounded-t-md"
  if (activeTab === label) className += " border-pink bg-pink"

  const handleOnClick = () => {
    onClick(label)
  }

  return (
    <button type="button" className={className} onClick={handleOnClick}>
      {label}
    </button>
  )
}

const ProductTabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label)

  const onClickTabItem = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="flex border-pink border-b space-x-1 overflow-hidden">
        {children.map(child => {
          const { label } = child.props

          return (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem}
            />
          )
        })}
      </div>

      <div className="tab-content">
        {children.map(child => {
          if (child.props.label !== activeTab) return undefined
          return child.props.children
        })}
      </div>
    </>
  )
}

export default ProductTabs
